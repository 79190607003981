@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-size: 18px;
    }
    ::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }
}

@layer components {
    .transition300ms {
        @apply transition duration-300;
    }

    .text-paragraph {
        @apply text-greywhite;
    }
}